@media (min-width: 767px) {
  .sidebar_overlay {
    display: none !important;
  }
}
@media (max-width: 767px) {
  .sidecontentmain {
    margin-left: 0;
  }
  .sidebarbox {
    left: -250px;
    transition: all 0.3s;
    &.active {
      left: 0;
      transition: all 0.3s;
    }
  }
  .pro-sidebar.md.collapsed {
    left: 0;
  }
  .form-label {
    min-height: auto;
  }
  .sidecontentmain.active {
    margin-left: 0;
  }
}
@media (max-width: 575px) {
  .sidecontent {
    border-radius: 10px;
  }
  .chartbox canvas {
    height: 300px;
  }

  .thankyou_box h5,
  .thankyou_box .h5 {
    font-size: 22px;
  }
  .thankyou_box h6 span,
  .thankyou_box .h6 span,
  .thankyou_box h6 b,
  .thankyou_box .h6 b {
    display: block;
  }
  .thankyou_box h6,
  .thankyou_box .h6 {
    font-size: 16px;
  }
  .thankyou_box i {
    font-size: 70px;
  }
  .otpbox input {
    width: 60px !important;
  }
  .admintopright li:not(:last-child) {
    margin-right: 0.5rem;
  }
  .adminlanguagechangediv {
    width: 160px;
    & select {
      font-size: 13px;
    }
  }
  .adminheader {
    padding: 0px 10px;
  }
  .admintopright img {
    height: 30px;
    width: 30px;
  }
  .themetoggle {
    & label {
      width: 40px;
      height: 20px;
      font-size: 12px;
    }
    & span {
      width: 14px;
      height: 14px;
    }
    & input[type="checkbox"]:checked + label span {
      transform: translateX(18px);
    }
  }
  .adminfooter p {
    font-size: 12px;
  }
  .sidemenuheader {
    padding: 5px 0;
  }
  .sidemenuheader img {
    height: 40px;
  }
}

@media (max-width: 400px) {
  .adminlanguagechangediv {
    width: 100px;
  }
}
