html[data-theme="light"] {
  --white: #fff;
  --black: #000;
}
html[data-theme="dark"] {
  $form-select-indicator-color: white;
  --black: #fff;
  --white: #000;
  --box-shadow: 0px 1px 3px rgba(255, 255, 255, 0.3);
  & .sidemenubox li a:hover,
  .sidemenubox li button:hover,
  .sidemenubox li a.active,
  .sidemenubox li button.active {
    color: #fff;
    background: $primary;
  }
  & .sidemenuheader,
  .form-control,
  .form-select,
  .adminfooter,
  .table-bordered > :not(caption) > *,
  .table > :not(:first-child),
  .adminheader,
  .sidebarbox {
    border-color: #323232;
  }
  & .pagesec,
  .modal-content, .counterbox {
    background-color: #000;
  }
  & .sidecontent,
  body,
  .submenu,
  .form-control,
  .form-select  {
    background-color: #333;
  }
  & .text-dark,
  .table,
  .btn:hover,
  .form-control,
  .form-select, .counterbox .counterboxdata p.value {
    color: #fff !important;
  }
  & .sidebarbox .sidebarmenu nav > ul > li > a.active, .sidebarbox .sidebarmenu nav > ul > li > a:hover{
    background-color: rgb(255 255 255 / 10%);
  }
  & .form-select {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$form-select-indicator-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>");
  }
}
